import SectionCard from '../SectionCard/SectionCard';
import CalendarSection from './CalendarSection';
import CalendarList from './List/CalendarList';
import useGetCalendarEventsSection from 'hooks/useGetCalendarEvents';
import { useContext } from 'react';
import { CalendarEventsContext } from 'context/CalendarEvents/CalendarEventsContext';
import { AppContext } from 'context/App/AppContext';
import { getFilteredEvents } from './tools/utils';

interface Props {
  section: CalendarSection;
}

const CalendarCard = ({ section }: Props) => {
  const {
    appState: {
      startParameters: { organisationId }
    }
  } = useContext(AppContext);
  const { loading, error } = useGetCalendarEventsSection(section.id.toString());
  const { calendarEventsState } = useContext(CalendarEventsContext);
  const events = getFilteredEvents(
    section,
    calendarEventsState.CalendarEventsSection?.events,
    organisationId
  );

  return (
    <SectionCard section={section} error={error} loading={loading} noActionButton={!events?.length}>
      {events && events.length && <CalendarList events={events} user={calendarEventsState.user} />}
    </SectionCard>
  );
};

export default CalendarCard;
