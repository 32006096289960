import { Grid, Typography } from '@material-ui/core';
import { ListEvent } from './List/ListEvent/ListEvent';
import styles from './styles';
import CalendarSection from './CalendarSection';
import { useContext } from 'react';
import useGetCalendarEventsSection from 'hooks/useGetCalendarEvents';
import { CalendarEventsContext } from 'context/CalendarEvents/CalendarEventsContext';
import Progress from 'components/Progress/Progress';
import { AppContext } from 'context/App/AppContext';
import { getFilteredEvents } from './tools/utils';

interface Props {
  section: CalendarSection;
}

const CalendarEventsPage = ({ section }: Props) => {
  const {
    appState: {
      startParameters: { organisationId }
    }
  } = useContext(AppContext);
  const classes = styles();
  const { loading } = useGetCalendarEventsSection(section.id.toString());
  const { calendarEventsState } = useContext(CalendarEventsContext);
  const events = getFilteredEvents(
    section,
    calendarEventsState.CalendarEventsSection?.events,
    organisationId
  );
  if (loading) return <Progress />;
  return (
    <Grid container direction='column'>
      <Grid className={classes.headingContainer}>
        <Typography variant='h5'>{section.name}</Typography>
        {section.description && (
          <Typography className={classes.subtitle}>{section.description}</Typography>
        )}
      </Grid>
      <Grid item className={classes.content}>
        {events &&
          events.map((e: any) => (
            <ListEvent key={e.id} event={e} user={calendarEventsState.user} />
          ))}
      </Grid>
    </Grid>
  );
};

export default CalendarEventsPage;
