import Section, { SectionType } from '../Section';

export default class FolderSection extends Section {
  parentSectionId: number;

  constructor(props: any) {
    super(props);

    this.type = SectionType.FOLDER;
    this.alertProps.error.txtKey = 'alert.fetchFailed';
    this.alertProps.noItems.txtKey = 'alert.noFolders';
    this.parentSectionId = props.parentSectionId;
  }
}
