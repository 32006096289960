import { Button, ButtonType } from '@groupbuilderoy/gb-components-library';
import { DialogType, EventType, CalendarReservation } from '../../interfaces';
import useStyles from './styles';
import useT from 'hooks/useT';

interface CalendarEventButtonsProps {
  event: EventType;
  userReservation: CalendarReservation | null;
  handleOpenDialog: any;
  handleNotParticipateEvent: () => void;
}

const CalendarEventButtons = ({
  event,
  userReservation,
  handleOpenDialog,
  handleNotParticipateEvent
}: CalendarEventButtonsProps) => {
  const t = useT();
  const styles = useStyles({});
  const isRegistrationDeadlineOver = (deadline: string) => {
    const currentDate = new Date();
    return currentDate > new Date(deadline);
  };

  // for event appointment
  const displayAddAppointmentButton = () => {
    if (
      !event.registrationDeadline ||
      (event.registrationDeadline && !isRegistrationDeadlineOver(event.registrationDeadline))
    ) {
      return (
        <div className={styles.buttonGroup}>
          <Button
            onClick={() => handleOpenDialog(DialogType.Add_Appointment)}
            type={ButtonType.contained}
            color={!userReservation ? 'primary' : 'secondary'}
            className={styles.buttonContainer}
            caption={t('calendarSection.registerButton')}
          />
        </div>
      );
    } else if (
      event.registrationDeadline &&
      isRegistrationDeadlineOver(event.registrationDeadline)
    ) {
      return <></>;
    }
  };
  // for event type_only
  const displayAddRegistrationButton = () => {
    if (
      !event.registrationDeadline ||
      (event.registrationDeadline && !isRegistrationDeadlineOver(event.registrationDeadline))
    ) {
      return (
        <div className={styles.buttonGroupSmall}>
          <Button
            onClick={() => handleOpenDialog(DialogType.Add_Registration)}
            color='primary'
            className={styles.buttonContainer}
            caption={t('calendarSection.registerButton')}
          />

          <Button
            onClick={handleNotParticipateEvent}
            color='secondary'
            className={styles.buttonContainer}
            caption={t('calendarSection.notParticipateButton')}
          />
        </div>
      );
    } else if (
      event.registrationDeadline &&
      isRegistrationDeadlineOver(event.registrationDeadline)
    ) {
      return <></>;
    }
  };

  const displayEditButton = (dialogType: DialogType) => {
    return (
      <div className={styles.buttonGroup}>
        <Button
          onClick={() => handleOpenDialog(dialogType)}
          color='secondary'
          className={styles.buttonContainer}
          caption={t('calendarSection.editButton')}
        />
      </div>
    );
  };

  const getButtons = (typeKey: string) => {
    switch (typeKey) {
      case 'TYPE_INVITE_ONLY':
        return (
          <>
            {!userReservation
              ? displayAddRegistrationButton()
              : displayEditButton(DialogType.Edit_Registration)}
          </>
        );
      case 'TYPE_APPOINTMENT':
        return (
          <>
            {!userReservation
              ? displayAddAppointmentButton()
              : displayEditButton(DialogType.Edit_Appointment)}
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{getButtons(event.type)}</>;
};

export default CalendarEventButtons;
