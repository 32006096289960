import Section, { SectionType } from '../Section';

export default class FormsSection extends Section {
  constructor(props: any) {
    super(props);

    this.type = SectionType.SURVEYS;
    this.alertProps.error.txtKey = 'forms.alert.failedToFetch';
    this.alertProps.noItems.txtKey = 'forms.alert.noFormsItems';
  }
}
