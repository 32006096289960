import { useTheme, Typography } from '@material-ui/core';
import DateSquare from 'components/DateSquare/DateSquare';
import { CalendarReservation, EventType, LocalFormatDateTimeType } from '../../interfaces';
import useStyles from './styles';
import EventButton from '../EventButton/EventButton';
import { useContext, useEffect, useRef, useState } from 'react';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import { Apartment } from 'context/Apartment/interfaces';
import useT from 'hooks/useT';
import CalendarEventDateDisplay from '../CalendarEventDialogs/CalendarEventDateDisplay';
import { localFormatDateTime } from 'utils/localFormatDateTime';
import { AppContext } from 'context/App/AppContext';

interface ListEventPropsType {
  event: EventType;
  user: any;
}

const findUserReservation = (
  event: EventType,
  userId: string,
  selectedApartment: Apartment | undefined
) => {
  const reservation = event.reservations.find(
    (reservation) =>
      reservation.author.auth0Id === userId && reservation.apartmentId === selectedApartment?.id
  );
  return reservation ? reservation : null;
};

export function ListEvent({ event, user }: ListEventPropsType) {
  const { appState } = useContext(AppContext);
  const { organisationId } = appState.startParameters;
  const t = useT();
  const { apartmentState } = useContext(ApartmentContext);
  const selectedApartment = apartmentState.selectedApartment;
  const styles = useStyles();
  const theme = useTheme();
  const [userReservation, setUserReservation] = useState<CalendarReservation | null>(
    findUserReservation(event, user.auth0Id, selectedApartment)
  );
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [isNarrow, setIsNarrow] = useState(false);

  useEffect(() => {
    if (event) {
      setUserReservation(findUserReservation(event, user.auth0Id, selectedApartment));
    }
  }, [event]);

  useEffect(() => {
    const handleResize = () => {
      if (rootRef.current) {
        setIsNarrow(rootRef.current.offsetWidth < 700);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.root} ref={rootRef}>
      <DateSquare
        showTime
        startDateTimeString={event.startTime}
        color={event.endTime ? theme.palette.primary.main : theme.palette.error.main}
        borderRadius='8px'
        textColor={
          organisationId === 'srv' ? theme.palette.sectionsHeader?.main : theme.palette.common.white
        }
      />

      <div className={`${styles.eventDetailContainer} ${isNarrow && styles.eventDetailNarrow}`}>
        <div className={`${styles.descriptionContainer}`}>
          {event.name && <Typography className={styles.descriptionTitle}>{event.name}</Typography>}
          {event.description && (
            <>
              <Typography className={styles.descriptionDescription}>{event.description}</Typography>
            </>
          )}

          {event.type == 'TYPE_INVITE_ONLY' && userReservation && (
            <Typography className={`${styles.descriptionDescription}`}>
              <strong>
                {userReservation.numberOfPersonsAttending > 1
                  ? `${userReservation.numberOfPersonsAttending} `
                  : `${userReservation.numberOfPersonsAttending} `}
                {t('calendarSection.personsRegisterToTheEvent')}
              </strong>
            </Typography>
          )}
          {event.type == 'TYPE_APPOINTMENT' &&
            userReservation &&
            userReservation.startTime &&
            userReservation.endTime && (
              <CalendarEventDateDisplay
                startTime={userReservation.startTime}
                endTime={userReservation.endTime}
                title={'calendarSection.appointmentReservedStatus'}
              />
            )}
          {!userReservation && event.registrationDeadline && (
            <Typography className={`${styles.descriptionDescription}`}>
              <strong> {t('calendarSection.registrationDeadline')}</strong>{' '}
              {/* {format(new Date(event.registrationDeadline), 'MMMM d, yyyy h:mm a', {
                locale: getDateFnsLocale()
              })} */}
              {localFormatDateTime(
                new Date(event.registrationDeadline),
                LocalFormatDateTimeType.Full
              )}
            </Typography>
          )}
        </div>
        <div className={`${styles.eventColumn} ${isNarrow && styles.eventColumnNarrow}`}>
          <EventButton event={event} userReservation={userReservation} />
        </div>
      </div>
    </div>
  );
}
