import CalendarSection from "../CalendarSection";
import { EventType } from "../interfaces";

export const getFilteredEvents = (section: CalendarSection, calendarEvents?: EventType[], organisationId?: string,) => {
    if (organisationId === 'srv' && calendarEvents) {
        if (section.name === 'Materiaalivalintojen ajanvaraus') {
            return calendarEvents.filter(
                (event: EventType) => event.name === 'Sisustustapaaminen'
            );

        } else if (section.name === 'Muuttotarkastuksen ajanvaraus') {
            return calendarEvents.filter(
                (event: EventType) => event.name === 'Muuttotarkastus'
            );
        } else {
            return []
        }
    } else {
        return calendarEvents;
    }
}